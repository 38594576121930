<template>
  <v-container>
    <!-- Banner header -->
    <Banner :title="'Proyectos'" />

    <!-- Data table projects -->
    <v-card class="my-8">
      <DataTable
        :headers="tableHeaders"
        :items="$store.state.projects.projects"
        :totalItems="$store.state.projects.totalCountProjects"
        @clickNextIcon="handleNextIcon"
        @clickPrevIcon="handlePrevIcon"
        @inputSearch="handleSearch"
        @changeItemsPerPage="getProjects"
      >
        <template v-slot:filters>
          <ProjectsFilters :elasticData="elasticData" @getProjects="getFilteredProjects" />
        </template>
        <template v-slot:[`item.id`]="{ item }">
          <router-link :to="`/proyectos/${item.id}`">{{ item.id }}</router-link>
        </template>
        <template v-slot:[`item.borrower`]="{ item }">
          <router-link :to="`/prestatarias/${item.borrower.id}`">{{ item.borrower.name }}</router-link>
        </template>
        <template v-slot:[`item.technician`]="{ item }">
          <router-link :to="`/tecnicos/${item.technician.id}`">{{ item.technician.name }}</router-link>
        </template>
        <template v-slot:[`item.partner`]="{ item }">
          <router-link :to="`/tecnicos/${item.partner.id}`">{{ item.partner.name }}</router-link>
        </template>
        <template v-slot:[`item.created_at`]="{ item }">
          {{ formatDateL(item.created_at) }}
        </template>
      </DataTable>
    </v-card>

    <TestsCalendarPayments v-if="user.role === 'SUPERADMIN'" />
  </v-container>
</template>

<script>
import { mapActions, mapState } from "vuex";
import FormatDateMixin from "@/mixins/FormatDateMixin";
import Banner from "@/components/elements/Banner";
import ProjectsFilters from "@/components/elements/project/ProjectsFilters";
import DataTable from "@/components/elements/DataTable";
import TestsCalendarPayments from "@/components/elements/project/TestsCalendarPayments";

export default {
  name: "Projects",
  components: {
    Banner,
    DataTable,
    TestsCalendarPayments,
    ProjectsFilters
  },
  mixins: [FormatDateMixin],
  data() {
    return {
      // Projects datatable
      tableHeaders: [
        { text: "Id", value: "id", sortable: false },
        { text: "Prestataria", value: "borrower", sortable: false },
        { text: "Técnico", value: "technician", sortable: false },
        { text: "Estado", value: "status", sortable: false },
        { text: "Partner", value: "partner", sortable: false },
        { text: "Moneda", value: "currency", sortable: false },
        { text: "Fecha creación", value: "created_at" }
      ],

      // Filters variables
      elasticData: {}
    };
  },
  computed: {
    ...mapState("user", ["user", "dbUser"])
  },
  async created() {
    await this.$store.dispatch("user/getDbUserInfo");
    if (this.user.role === "TECHNICIAN") {
      await this.getProjects({ size: 10, sort: { created_at: "DESC" }, filter: { "technician.id": this.user.user_id } });
      this.elasticData = { size: 10, sort: { created_at: "DESC" }, filter: { "technician.id": this.user.user_id } };
    } else if (this.user.role === "SUPERTECHNICIAN") {
      await this.getProjects({ size: 10, sort: { created_at: "DESC" }, filter: { "partner.id": this.dbUser.technician.partner_id } });
      this.elasticData = { size: 10, sort: { created_at: "DESC" }, filter: { "partner.id": this.dbUser.technician.partner_id } };
    } else await this.getProjects({ size: 10, sort: { created_at: "DESC" } });
  },
  methods: {
    ...mapActions({
      getProjects: "projects/getProjects"
    }),

    async handlePrevIcon(elasticData) {
      this.elasticData = elasticData;
      await this.getProjects(elasticData);
    },

    async handleNextIcon(elasticData) {
      this.elasticData = elasticData;
      await this.getProjects(elasticData);
    },

    async handleSearch(elasticData) {
      this.elasticData = elasticData;
      await this.getProjects(elasticData);
    },

    async getFilteredProjects(elasticData) {
      if (elasticData.from) elasticData.from = 0;
      await this.getProjects(elasticData);
    }
  }
};
</script>
