<template>
  <div>
    <!-- Data table tests -->
    <v-card class="my-8">
      <v-toolbar color="primary" dense>
        <span>Tests de calendarios de pago</span>
        <v-spacer />
        <v-btn small @click="dialogCreateTest = true">Crear test</v-btn>
      </v-toolbar>

      <DataTable
        :headers="tableHeadersTest"
        :items="$store.state.projects.testsCalendarPayments"
        :totalItems="$store.state.projects.totalCountTestsCalendarPayments"
        @clickNextIcon="handleNextIcon"
        @clickPrevIcon="handlePrevIcon"
        @inputSearch="handleSearch"
        @changeItemsPerPage="getTestsCalendarPayments"
      >
        <template v-slot:[`item.id`]="{ item }">
          <router-link :to="`/test-calendar/${item.id}`">{{ item.id }}</router-link>
        </template>
        <template v-slot:[`item.active_at`]="{ item }">
          {{ formatDateL(item.active_at) }}
        </template>
        <template v-slot:[`item.amount_invested`]="{ item }">
          {{ currency(item.amount_invested, { fromCents: true }) }}
        </template>
        <template v-slot:[`item.created_at`]="{ item }">
          {{ formatDateL(item.created_at) }}
        </template>
      </DataTable>
    </v-card>

    <!-- Dialog create test-->
    <DialogBox :model="dialogCreateTest">
      <template slot="toolbar">
        <span>Crear calendar test</span>
      </template>
      <template slot="content">
        <v-form ref="formCreateTest" v-model="formCreateTestValid">
          <v-select
            v-model="newTest.financial_product.type"
            :items="['COOPERATIVE', 'YUNUS', 'NOMINAL']"
            label="Tipo de producto"
            :rules="formRules.textRules"
            @change="newTest.financial_product.conditions.zero_interest_in_waiting_period = false"
          />
          <div v-if="newTest.financial_product.type" class="secondary pa-4 mb-3">
            <p class="text-h6">Producto financiero</p>
            <v-text-field
              v-if="newTest.financial_product.type === 'COOPERATIVE'"
              v-model.number="newTest.financial_product.conditions.arrear_coeficient"
              type="number"
              label="Coeficiente de mora"
              :rules="formRules.numberRulesAllowZero"
            />
            <v-text-field
              v-model.number="newTest.financial_product.conditions.monthly_fees"
              type="number"
              label="Cuotas mensuales"
              :rules="formRules.numberRules"
            />
            <v-text-field
              v-model.number="newTest.financial_product.conditions.waiting_period"
              type="number"
              label="Meses de carencia"
              :rules="formRules.numberRulesAllowZero"
            />
            <v-text-field
              v-model.number="newTest.financial_product.conditions.monthly_interest"
              type="number"
              label="Interés mensual"
              :rules="formRules.numberRulesAllowZero"
            />
            <v-text-field
              v-if="newTest.financial_product.type === 'COOPERATIVE'"
              v-model.number="newTest.financial_product.conditions.vat"
              type="number"
              label="IVA"
              :rules="formRules.numberRulesAllowZero"
            />
            <v-text-field
              v-if="newTest.financial_product.type === 'COOPERATIVE'"
              v-model.number="newTest.financial_product.conditions.arrear_vat"
              type="number"
              label="IVA por mora"
              :rules="formRules.numberRulesAllowZero"
            />
            <v-text-field
              v-if="newTest.financial_product.type === 'COOPERATIVE'"
              v-model.number="newTest.financial_product.conditions.commission"
              type="number"
              label="Comisión"
              :rules="formRules.numberRulesAllowZero"
            />
            <v-text-field
              v-if="newTest.financial_product.type === 'COOPERATIVE'"
              v-model.number="newTest.financial_product.conditions.commission_real"
              type="number"
              label="Comisión real"
              :rules="formRules.numberRulesAllowZero"
            />
            <v-checkbox
              v-if="newTest.financial_product.type === 'COOPERATIVE'"
              v-model="newTest.financial_product.conditions.zero_interest_in_waiting_period"
              label="No capitalizar interes en periodo de carencia"
            />
          </div>

          <DatePicker :parent_model="newTest.active_at" label="Fecha de activación" @change="setDateOfActive" :required="true" />
          <v-text-field v-model.number="newTest.amount_credited" type="number" label="Financiado" :rules="formRules.numberRules" />

          <v-card class="pa-5">
            <p class="text-h6">Inversiones</p>
            <div v-for="(investment, i) in newTest.investments" :key="i" class="secondary pa-5 mb-2">
              <v-toolbar dense flat class="secondary">
                <v-toolbar-title>Inversión {{ i + 1 }}</v-toolbar-title>
                <v-spacer />
                <v-btn v-if="i != 0" icon elevation="2" small color="red" @click="removeInvestment(i)">
                  <v-icon small>delete</v-icon>
                </v-btn>
              </v-toolbar>
              <v-text-field v-model.number="investment.amount" type="number" label="Cantidad" :rules="formRules.numberRules" />
            </div>
            <div class="text-right">
              <v-btn elevation="2" small @click="addInvestment">
                <v-icon>add</v-icon>
              </v-btn>
            </div>
            <p v-if="!investmentsAmountsAreEqualToCreditAmount" class="red--text">
              El importe financiado debe ser igual a la suma de las inversiones
            </p>
          </v-card>
        </v-form>
      </template>
      <template slot="actions">
        <v-btn text @click="closeDialog('dialogCreateTest', 'formCreateTest')">Cerrar</v-btn>
        <v-btn text @click="createTestCalendarPayments()">Crear</v-btn>
      </template>
    </DialogBox>
  </div>
</template>

<script>
import { mapActions } from "vuex";
import CommonMixin from "@/mixins/CommonMixin";
import FormatDateMixin from "@/mixins/FormatDateMixin";
import FormRulesMixin from "@/mixins/FormRulesMixin";
import DataTable from "@/components/elements/DataTable";
import DialogBox from "@/components/elements/DialogBox";
import DatePicker from "@/components/elements/DatePicker";
import _ from "lodash";
import * as currency from "currency.js";

export default {
  components: { DataTable, DatePicker, DialogBox },
  mixins: [CommonMixin, FormatDateMixin, FormRulesMixin],
  data() {
    return {
      tableHeadersTest: [
        { text: "Id", value: "id", sortable: false },
        { text: "Financiado", value: "amount_invested", sortable: false },
        { text: "Tipo", value: "type", sortable: false },
        { text: "Fecha activación", value: "active_at", sortable: false },
        { text: "Fecha de creación", value: "created_at" }
      ],

      // Fields for create test
      newTest: {
        financial_product: {
          type: "",
          conditions: {
            arrear_coeficient: 0,
            monthly_fees: 10,
            waiting_period: 2,
            monthly_interest: 0,
            vat: 0,
            arrear_vat: 0,
            commission: 0,
            commission_real: 0,
            zero_interest_in_waiting_period: false
          },
          requirements: {
            min_credits: 0,
            offices: [""]
          }
        },
        active_at: "",
        amount_credited: 0,
        investments: [
          {
            amount: 0
          }
        ]
      },

      // Dialogs
      dialogCreateTest: false,
      formCreateTestValid: false
    };
  },
  computed: {
    investmentsAmountsAreEqualToCreditAmount() {
      let investmentsAmounts = 0;
      for (let i = 0; i < this.newTest.investments.length; i++) {
        const element = this.newTest.investments[i];
        investmentsAmounts += element.amount;
      }

      return this.newTest.amount_credited === investmentsAmounts;
    },
    _() {
      return _;
    },
    currency() {
      return currency;
    }
  },
  watch: {
    "newTest.financial_product.type"(value) {
      this.newTest.financial_product.conditions = this.$options.data().newTest.financial_product.conditions;
      if (value === "NOMINAL") this.newTest.financial_product.conditions.monthly_interest = 1.2;
      else if (value === "YUNUS") this.newTest.financial_product.conditions.monthly_interest = 2.495;
    }
  },
  async created() {
    await this.getTestsCalendarPayments({ size: 10, sort: { created_at: "DESC" } });
  },
  methods: {
    ...mapActions({
      getTestsCalendarPayments: "projects/getTestsCalendarPayments"
    }),

    async createTestCalendarPayments() {
      if (this.$refs.formCreateTest.validate() && this.investmentsAmountsAreEqualToCreditAmount) {
        let newTestFormattedData = this._.cloneDeep(this.newTest);

        newTestFormattedData.active_at = this.formatDateToISO8601(newTestFormattedData.active_at);
        for (const investment of newTestFormattedData.investments) {
          investment.amount = this.convertToInt(investment.amount);
        }
        newTestFormattedData.investments = this.arrayInvestmentsToObject(newTestFormattedData.investments);
        newTestFormattedData.amount_credited = this.convertToInt(newTestFormattedData.amount_credited);

        if (newTestFormattedData.financial_product.type !== "COOPERATIVE") {
          newTestFormattedData.financial_product.conditions.arrear_coeficient = 0;
          newTestFormattedData.financial_product.conditions.vat = 0;
          newTestFormattedData.financial_product.conditions.arrear_vat = 0;
          newTestFormattedData.financial_product.conditions.commission = 0;
          newTestFormattedData.financial_product.conditions.commission_real = 0;
        }

        await this.$store.dispatch("projects/createTestCalendarPayments", newTestFormattedData);
        await this.getTestsCalendarPayments({ size: 10, sort: { created_at: "DESC" } });
        this.closeDialog("dialogCreateTest", "formCreateTest");
      }
    },

    addInvestment() {
      this.newTest.investments.push({ amount: 0 });
    },

    removeInvestment(index) {
      this.newTest.investments.splice(index, 1);
    },

    arrayInvestmentsToObject(arr) {
      let obj = {};
      for (let i = 0; i < arr.length; i++) {
        const investment = arr[i];
        obj[`INVESTMENT_${i + 1}`] = {
          amount: investment.amount,
          investor_id: `${i + 1}`
        };
      }
      return obj;
    },

    setDateOfActive(value) {
      this.newTest.active_at = value;
    },

    async handlePrevIcon(elasticData) {
      await this.getTestsCalendarPayments(elasticData);
    },

    async handleNextIcon(elasticData) {
      await this.getTestsCalendarPayments(elasticData);
    },

    async handleSearch(elasticData) {
      await this.getTestsCalendarPayments(elasticData);
    },

    // Close dialogs, clear and reset validation form fields
    closeDialog(dialogModel, formRef) {
      this.$refs[formRef].resetValidation();
      this[dialogModel] = false;

      this.project = this.$options.data().project;
      this.projectTest = this.$options.data().projectTest;
    }
  }
};
</script>
