<template>
  <v-row class="mx-4">
    <v-col cols="12" md="auto" class="px-0 px-sm-1">
      <v-menu v-model="menu" :close-on-content-click="false" :nudge-width="200" offset-x>
        <template v-slot:activator="{ on, attrs }">
          <v-btn v-bind="attrs" v-on="on" outlined height="40px" width="100%"> <v-icon>add</v-icon> filtro</v-btn>
        </template>

        <v-card>
          <v-card-text>
            <p class="mb-1">Selecciona el tipo de filtro:</p>
            <v-divider />
            <v-checkbox
              v-if="filterPermissions.status.includes(user.role)"
              v-model="filtersToShow"
              label="Estado"
              value="status"
              hide-details
              dense
              @change="handleStatusCheckBox()"
            />
            <v-checkbox
              v-if="filterPermissions.country.includes(user.role)"
              v-model="filtersToShow"
              label="País"
              value="country"
              hide-details
              dense
              @change="handleCountryCheckBox()"
            />
            <v-checkbox
              v-if="filterPermissions.partner.includes(user.role)"
              v-model="filtersToShow"
              label="Partner"
              value="partner"
              hide-details
              dense
              @change="handlePartnerCheckBox()"
            />
            <v-checkbox
              v-if="filterPermissions.office.includes(user.role)"
              v-model="filtersToShow"
              label="Oficina"
              value="office"
              hide-details
              dense
              @change="handleOfficeCheckBox()"
            />
            <v-checkbox
              v-if="filterPermissions.technician.includes(user.role)"
              v-model="filtersToShow"
              label="Técnico"
              value="technician"
              hide-details
              dense
              @change="handleTechnicianCheckBox()"
            />
          </v-card-text>
        </v-card>
      </v-menu>
    </v-col>
    <v-col v-if="filtersToShow.includes('status')" cols="12" sm="6" md="2" class="d-flex px-0 px-sm-1">
      <v-select
        v-model="filters.find((e) => e.name === 'status').selected"
        :items="['ACTIVE', 'ANTICIPATED', 'APPROVED', 'CANCELLED', 'CONDONED', 'DEFENDANT', 'FINISHED', 'FUNDED', 'INVESTABLE', 'SENT']"
        label="Estado"
        :item-text="'name'"
        item-value="id"
        hide-details
        outlined
        dense
        prepend-inner-icon="filter_list"
        @change="handleStatusSelect()"
      />
    </v-col>
    <v-col v-if="filtersToShow.includes('country')" cols="12" sm="6" md="2" class="d-flex px-0 px-sm-1">
      <v-select
        v-model="filters.find((e) => e.name === 'country').selected"
        :items="countries"
        label="País"
        :item-text="'name'"
        item-value="id"
        hide-details
        outlined
        dense
        prepend-inner-icon="filter_list"
        @change="handleCountrySelect()"
      />
    </v-col>
    <v-col v-if="filtersToShow.includes('partner')" cols="12" sm="6" md="2" class="d-flex px-0 px-sm-1">
      <v-select
        v-model="filters.find((e) => e.name === 'partner').selected"
        :items="partners"
        label="Partner"
        :item-text="'name'"
        item-value="id"
        hide-details
        outlined
        dense
        prepend-inner-icon="filter_list"
        @change="handlePartnerSelect()"
      />
    </v-col>
    <v-col v-if="filtersToShow.includes('office')" cols="12" sm="6" md="2" class="d-flex px-0 px-sm-1">
      <v-select
        v-model="filters.find((e) => e.name === 'office').selected"
        :items="offices"
        label="Oficina"
        :item-text="'name'"
        item-value="id"
        hide-details
        outlined
        dense
        prepend-inner-icon="filter_list"
        @change="handleOfficeSelect()"
      />
    </v-col>
    <v-col v-if="filtersToShow.includes('technician')" cols="12" sm="6" md="2" class="d-flex px-0 px-sm-1">
      <v-select
        v-model="filters.find((e) => e.name === 'technician').selected"
        :items="technicians"
        label="Técnico"
        :item-text="'full_name'"
        item-value="id"
        hide-details
        outlined
        dense
        prepend-inner-icon="filter_list"
        @change="handleTechnicianSelect()"
      />
    </v-col>
  </v-row>
</template>

<script>
import { mapState } from "vuex";
import FormatDateMixin from "@/mixins/FormatDateMixin";
import elasticSearchApi from "@/api/elasticSearchApi";
import _ from "lodash";

export default {
  name: "ProjectsFilters",
  mixins: [FormatDateMixin],
  props: {
    elasticData: {
      type: Object,
      required: true
    }
  },
  data() {
    return {
      menu: false,
      filters: [],
      filtersToShow: [],

      // Items from elastic
      countries: [],
      partners: [],
      offices: [],
      technicians: [],

      // Validating filters by user role
      filterPermissions: {
        status: ["SUPERADMIN", "ADMIN", "SUPERTECHNICIAN", "TECHNICIAN"],
        country: ["SUPERADMIN", "ADMIN"],
        partner: ["SUPERADMIN", "ADMIN"],
        office: ["SUPERADMIN", "ADMIN", "SUPERTECHNICIAN"],
        technician: ["SUPERADMIN", "ADMIN", "SUPERTECHNICIAN"]
      }
    };
  },
  computed: {
    ...mapState("user", ["user"]),
    _() {
      return _;
    }
  },
  methods: {
    // -------------- Checkboxes handlers --------------
    handleStatusCheckBox() {
      if (this.filters.some((e) => e.name === "status")) {
        const indexToRemove = this.filters.findIndex((e) => e.name === "status");
        this.filters.splice(indexToRemove, 1);
        delete this.elasticData.filter["status"];
        if (this._.isEmpty(this.elasticData.filter)) delete this.elasticData.filter;

        this.$emit("getProjects", this.elasticData);
      } else {
        this.filters.push({ name: "status" });
      }
    },

    async handleCountryCheckBox() {
      if (this.filters.some((e) => e.name === "country")) {
        const indexToRemove = this.filters.findIndex((e) => e.name === "country");
        this.filters.splice(indexToRemove, 1);
        delete this.elasticData.filter["country.id"];

        if (
          !this.filtersToShow.includes("partner") &&
          !this.filtersToShow.includes("office") &&
          this.filtersToShow.includes("technician")
        ) {
          await this.getTechnicians();
        } else if (!this.filtersToShow.includes("partner") && this.filtersToShow.includes("office")) await this.getOffices();
        else if (this.filtersToShow.includes("partner")) await this.getPartners();
        else if (this._.isEmpty(this.elasticData.filter)) delete this.elasticData.filter;

        this.$emit("getProjects", this.elasticData);
      } else {
        this.filters.push({ name: "country" });
        await this.getCountries();
      }
    },

    async handlePartnerCheckBox() {
      if (this.filters.some((e) => e.name === "partner")) {
        const indexToRemove = this.filters.findIndex((e) => e.name === "partner");
        this.filters.splice(indexToRemove, 1);
        delete this.elasticData.filter["partner.id"];

        if (!this.filtersToShow.includes("office") && this.filtersToShow.includes("technician")) await this.getTechnicians();
        else if (this.filtersToShow.includes("office")) await this.getOffices();
        else if (this._.isEmpty(this.elasticData.filter)) delete this.elasticData.filter;

        this.$emit("getProjects", this.elasticData);
      } else {
        this.filters.push({ name: "partner" });
        const countryOptionSelected = this.filters.find((e) => e.name === "country")?.selected;

        if (countryOptionSelected) await this.getPartners({ filter: { "country.id": countryOptionSelected } });
        else await this.getPartners();
      }
    },

    async handleOfficeCheckBox() {
      if (this.filters.some((e) => e.name === "office")) {
        const indexToRemove = this.filters.findIndex((e) => e.name === "office");
        this.filters.splice(indexToRemove, 1);
        delete this.elasticData.filter["office.id"];

        if (this.filtersToShow.includes("technician")) await this.getTechnicians();
        else if (this._.isEmpty(this.elasticData.filter)) delete this.elasticData.filter;

        this.$emit("getProjects", this.elasticData);
      } else {
        this.filters.push({ name: "office" });
        const countryOptionSelected = this.filters.find((e) => e.name === "country")?.selected;
        const partnerOptionSelected = this.filters.find((e) => e.name === "partner")?.selected;

        if (countryOptionSelected) await this.getOffices({ filter: { "country.id": countryOptionSelected } });
        else if (partnerOptionSelected) await this.getOffices({ filter: { "partner.id": partnerOptionSelected } });
        else await this.getOffices();
      }
    },

    async handleTechnicianCheckBox() {
      if (this.filters.some((e) => e.name === "technician")) {
        const indexToRemove = this.filters.findIndex((e) => e.name === "technician");
        this.filters.splice(indexToRemove, 1);
        delete this.elasticData.filter["technician.id"];
        if (this._.isEmpty(this.elasticData.filter)) delete this.elasticData.filter;

        this.$emit("getProjects", this.elasticData);
      } else {
        this.filters.push({ name: "technician" });
        const countryOptionSelected = this.filters.find((e) => e.name === "country")?.selected;
        const partnerOptionSelected = this.filters.find((e) => e.name === "partner")?.selected;
        const officeOptionSelected = this.filters.find((e) => e.name === "office")?.selected;

        if (countryOptionSelected) await this.getTechnicians({ filter: { "country.id": countryOptionSelected } });
        else if (partnerOptionSelected) await this.getTechnicians({ filter: { "partner.id": partnerOptionSelected } });
        else if (officeOptionSelected) await this.getTechnicians({ filter: { "office.id": officeOptionSelected } });
        else await this.getTechnicians();
      }
    },

    // -------------- Selects handlers --------------
    handleStatusSelect() {
      const statusOptionSelected = this.filters.find((e) => e.name === "status")?.selected;

      this.elasticData.filter
        ? (this.elasticData.filter["status"] = statusOptionSelected)
        : (this.elasticData.filter = { ["status"]: statusOptionSelected });

      this.$emit("getProjects", this.elasticData);
    },

    async handleCountrySelect() {
      const countryOptionSelected = this.filters.find((e) => e.name === "country")?.selected;

      this.elasticData.filter
        ? (this.elasticData.filter["country.id"] = countryOptionSelected)
        : (this.elasticData.filter = { ["country.id"]: countryOptionSelected });

      if (this.filtersToShow.includes("partner")) {
        delete this.elasticData.filter["partner.id"];
        await this.getPartners({ filter: { "country.id": countryOptionSelected } });
      } else if (this.filtersToShow.includes("office")) {
        delete this.elasticData.filter["office.id"];
        await this.getOffices({ filter: { "country.id": countryOptionSelected } });
      } else if (this.filtersToShow.includes("technician")) {
        delete this.elasticData.filter["technician.id"];
        await this.getTechnicians({ filter: { "country.id": countryOptionSelected } });
      }

      this.$emit("getProjects", this.elasticData);
    },

    async handlePartnerSelect() {
      const partnerOptionSelected = this.filters.find((e) => e.name === "partner")?.selected;

      this.elasticData.filter
        ? (this.elasticData.filter["partner.id"] = partnerOptionSelected)
        : (this.elasticData.filter = { ["partner.id"]: partnerOptionSelected });

      if (this.filtersToShow.includes("office")) {
        delete this.elasticData.filter["office.id"];
        await this.getOffices({ filter: { "partner.id": partnerOptionSelected } });
      } else if (this.filtersToShow.includes("technician")) {
        delete this.elasticData.filter["technician.id"];
        await this.getTechnicians({ filter: { "partner.id": partnerOptionSelected } });
      }

      this.$emit("getProjects", this.elasticData);
    },

    async handleOfficeSelect() {
      const officeOptionSelected = this.filters.find((e) => e.name === "office")?.selected;

      this.elasticData.filter
        ? (this.elasticData.filter["office.id"] = officeOptionSelected)
        : (this.elasticData.filter = { ["office.id"]: officeOptionSelected });

      if (this.filtersToShow.includes("technician")) {
        delete this.elasticData.filter["technician.id"];
        await this.getTechnicians({ filter: { "office.id": officeOptionSelected } });
      }

      this.$emit("getProjects", this.elasticData);
    },

    handleTechnicianSelect() {
      const technicianOptionSelected = this.filters.find((e) => e.name === "technician")?.selected;

      this.elasticData.filter
        ? (this.elasticData.filter["technician.id"] = technicianOptionSelected)
        : (this.elasticData.filter = { ["technician.id"]: technicianOptionSelected });

      console.log(this.elasticData);

      this.$emit("getProjects", this.elasticData);
    },

    // -------------- Elastic petitions --------------
    async getCountries() {
      try {
        const response = await elasticSearchApi.post("/country", { must: { exists: { field: "vat" } } });
        const sortedCountries = this.sortArray(response.data.data, "name");
        this.countries = sortedCountries;
      } catch (error) {
        console.log(error);
      }
    },

    async getPartners(data = {}) {
      try {
        const response = await elasticSearchApi.post("/partner", data);
        const sortedPartners = this.sortArray(response.data.data, "name");
        this.partners = sortedPartners;
      } catch (error) {
        console.log(error);
      }
    },

    async getOffices(data = {}) {
      try {
        const response = await elasticSearchApi.post("/office", data);
        const sortedOffices = this.sortArray(response.data.data, "name");
        this.offices = sortedOffices;
      } catch (error) {
        console.log(error);
      }
    },

    async getTechnicians(data = {}) {
      try {
        const response = await elasticSearchApi.post("/technician", data);
        const sortedTechnicians = this.sortArray(response.data.data, "full_name");
        this.technicians = sortedTechnicians;
      } catch (error) {
        console.log(error);
      }
    },

    // -------------- Other methods --------------
    sortArray(array, name) {
      return array.sort((a, b) => a[name].localeCompare(b[name]));
    }
  }
};
</script>
